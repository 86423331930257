import React from 'react';
import Header from '../components/CommonHeader';
import FAQComponent from '../components/FAQComponent';
import Footer from '../components/Footer';
import AboutUs from '../components/AboutUs';
import HeaderBar from '../components/HeaderBar';


function About(){

    return(
        <>
            <HeaderBar />
            <AboutUs />
            <FAQComponent />
            <Footer />
        </>

    )

}

export default  About;