// ModalContext.js
import { createContext, useContext, useState } from 'react';

const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [onConfirm, setOnConfirm] = useState(() => () => {});

  const showModal = (content, title, confirmAction) => {
    console.log("in showModal")
    setModalContent(content);
    setModalTitle(title);
    setOnConfirm(() => () => {
      confirmAction();
      setIsModalOpen(false); // Close modal after confirmation
    });
    setIsModalOpen(true);
  };

  const hideModal = () => {
    setIsModalOpen(false);
  };

  return (
    <ModalContext.Provider value={{ isModalOpen, modalContent, modalTitle, onConfirm, showModal, hideModal }}>
      {children}
    </ModalContext.Provider>
  );
};
