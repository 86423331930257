// src/components/InfoDiv1/index.js
import React from 'react';
import './HowItWorks.css'; // Make sure to create an accompanying CSS file

const HowItWorks = () => {
  return (
    <div className="howitworks">
      <div>
        <h2>HOW IT WORKS</h2>
      </div>
      <div className='how-wrapper'>
        <div className='image-container'>
          <img src="/work-process.svg" alt="work process" className="rotation-image" />
        </div>
        <div className='howitworks-desc'>
          <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
            <li>
              <i className='hiw-icon fas fa-columns'></i>
              <p className='how-it-works-point'>Start by browsing through the algo Portfolios and their performance stats we offer. If you like one of them, click on invest.</p>
              <br />
            </li>
            <li>
              <i className='hiw-icon fas fa-wallet'></i>
              <p className='how-it-works-point'>Connect your metamask wallet, transfer USDT over binance smartchain (support for other tokens and chains coming soon).</p>
              <br />
            </li>
            <li>
              <i className='hiw-icon fas fa-coins'></i>
              <p className='how-it-works-point'>You will get tokens representing the Portfolio you have chosen based on its approx. NAV.</p>
              <br />
            </li>
            <li>
              <i className='hiw-icon fa fa-check-circle'></i>
              <p className='how-it-works-point'>The USDT you sent will get deployed in trading in about an hour.</p>
              <br />
            </li>
            <li>
              <i className='hiw-icon fas fa-chart-line'></i>
              <p className='how-it-works-point'>The NAV of the portfolio keeps changing depending on the trading P&L.</p>
              <br />
            </li>
            <li>
              <i className='hiw-icon fa fa-check-circle'></i>
              <p className='how-it-works-point'>Whenever you like to redeem your investment, You can redeem at NAV (minus transaction costs) using the redeem button.</p>
              <br />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
