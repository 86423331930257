import React, { useState, useEffect } from 'react';
import './InvestApp.css';
import Web3 from 'web3';
import { useMetamask } from '../Contexts/MetamaskContext';
import { switchToBNB } from '../Utils/MetaMaskUtils';
import config from '../config';
import { fetchBundles } from '../Utils/BackendFetch';
import CommonHeader from './CommonHeader';
import Joyride from 'react-joyride';





function InvestApp() {
  // This should come from your backend or state management
  const { selectedNetwork,selectAccount,usdtBalace } = useMetamask();
  console.log("selectedNetwork : ",selectedNetwork,usdtBalace)

  const [runTour, setRunTour] = useState(false);
  const [steps, setSteps] = useState([
    {
      target: '.invest',
      content: 'Click on this to Invest.',
      placement: 'bottom',
    },
    {
      target: '.redeem',
      content: 'Click on this to see you portfolio token and balance. You can also redeem your tokens.',
      placement: 'bottom',
    },
    {
      target: '.select-portfolio',
      content: 'This is where you can select a portfolio to invest in.',
      placement: 'bottom',
    },
    {
      target: '.you-pay-input',
      content: 'Enter the amount you wish to invest here.',
      placement: 'top',
    },
    {
      target: '.coin-label',
      content: 'This shows the currency that you will be investing with.',
      placement: 'bottom',
    },
    {
      target: '.you-recieve-input',
      content: 'This shows the type of coin you will recieve and number of coins you will recieve.',
      placement: 'top',
    },    
    {
      target: '.about',
      content: 'Click here to know more about us.',
      placement: 'top',
    },
    {
      target: '.porfolios',
      content: 'Click here to check our portfolios.',
      placement: 'top',
    },
    // You can add more steps as needed
  ]);
  
  
  const tokenAddress = '0x55d398326f99059fF775485246999027B3197955';
  const USDT_ABI = [
      {
        "constant": false,
        "inputs": [
          {
            "name": "_to",
            "type": "address"
          },
          {
            "name": "_value",
            "type": "uint256"
          }
        ],
        "name": "transfer",
        "outputs": [
          {
            "name": "",
            "type": "bool"
          }
        ],
        "type": "function"
      }
    ];
  const [selectedTab, setSelectedTab] = useState('Invest');
  const [bundles, setBundles] = useState([]);
  const [selectedBundle, setSelectedBundle] = useState(bundles[0]);
  const [amountToPay, setAmountToPay] = useState('');
  const [coinsToReceive, setCoinsToReceive] = useState(0);
  const [rtiCoinsToReceive, setRtiCoinsToReceive] = useState(0);
  const [transactionHash, setTransactionHash] = useState('');
  const receiver = "0x21e164550cb3680acf5d69d0a8884bdd79ae53c1";
  const [selectedOption, setSelectedOption] = useState('rti'); // Assuming 'rti' is the default
  const [showInfoText, setShowInfoText] = useState(false);
  const [primaryWallet, setPrimaryWallet] = useState('');
  const [walletData, setWalletData] = useState([]);
  // useEffect(() => {
  //   // Preselect the bundle if redirected from the "Invest" button
  //   // The logic here depends on how you pass the selected bundle name
  //   // For example, it could be from the URL or some state management
  //   const preselectedBundleName = 'Bundle 1'; // Replace with real preselected bundle name
  //   const preselectedBundle = bundles.find(bundle => bundle.name === preselectedBundleName);
  //   setSelectedBundle(preselectedBundle || bundles[0]);
  // }, [bundles]);
  useEffect(() => {

    const getBundles = async () =>{
      console.log("get bundles");
      let data = await fetchBundles(); 
      console.log(data)
      setBundles(data);
      if(data){
        setSelectedBundle(data[0]);
      }
      setAmountToPay(data[0].minimum_quantity);
      calculateCoins(data[0].minimum_quantity,data[0].nav);
      

    }
    const takeTour = () => {
      console.log("Tour called",localStorage.getItem('tourTaken'),!localStorage.getItem('tourTaken'));
      // if (!localStorage.getItem('tourTaken')) {
        console.log("setting tour")
        setRunTour(true);
        console.log("tour set")
        localStorage.setItem('tourTaken', 'true');
      // }
    }
    takeTour()
    getBundles()
    getWalletBalances()
}, []); 

const handleJoyrideCallback = (data) => {
  const { status } = data;
  const finishedStatuses = ['finished', 'skipped'];
  if (finishedStatuses.includes(status)) {
    setRunTour(false);
  }
};

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setShowInfoText(event.target.value === 'rti');
  };

  const getWalletBalances = async () =>{
    try {
      const web3 = new Web3(window.ethereum);
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const accounts = await web3.eth.getAccounts();
      setPrimaryWallet(accounts[0])
      const response = await fetch(`${config.API_BASE_URL}/userWallet?address=${accounts[0]}`);
      const data = await response.json();
      setWalletData(data);
      
    }
    catch(error){
      console.log(error)
    }

  }

  const handleSend = async (e) => {
    e.preventDefault();
    console.log("Handle send");
  
    if (!window.ethereum) {
      alert('Please install MetaMask to use this feature.');
      return;
    }
  
    const web3 = new Web3(window.ethereum);
    //const web3 = new Web3('https://bsc-dataseed.binance.org/');
    const usdtContractAddress = '0x55d398326f99059fF775485246999027B3197955'; // USDT contract address on Binance Smart Chain
  
    try {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const accounts = await web3.eth.getAccounts();
      const contract = new web3.eth.Contract(USDT_ABI, usdtContractAddress);

      // const gasPrice = await web3.eth.getGasPrice(); // Or set your own gas price based on network conditions
      // const estimatedGas = await contract.methods.transfer(receiver, amountToSend).estimateGas({ from: accounts[0] });
      const gasLimit = web3.utils.toHex(21000);
      const qtys = quantities(selectedOption,amountToPay);
      let totalAmt = parseFloat(qtys[0]) + qtys[1];
      console.log(totalAmt,qtys[0],qtys[1]);
      const amountToSend = web3.utils.toWei(totalAmt, 'ether'); // Convert USDT amount to Wei (note: this assumes USDT uses 18 decimals which is standard for ERC20 tokens)
      console.log("from : ",accounts[0])
  
      contract.methods.transfer(receiver, amountToSend).send({ from: accounts[0]})
      .on('transactionHash', async (hash) => {
        console.log(`Transaction Hash: ${hash}`);
        setTransactionHash(hash);
        // Post to your backend when the transaction hash is received
        const transactionData = {
          transaction_hash: hash,
          from: accounts[0],
          to: receiver,
          quantity: amountToPay,
          token: 'USDT',
          status: 'INIT',
          rti_quantity: qtys[1],
          bundle_name: selectedBundle.id,
          bundle_quantity: coinsToReceive
        };
        await fetch(config.API_BASE_URL+'/transaction/transactionHash', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(transactionData)
        });
      })
      .on('receipt', async (receipt) => {
        console.log("Receipt:", receipt);
        // Post to your backend when the transaction is confirmed
        const receiptData = {
          transaction_hash: receipt.transactionHash,
          from: accounts[0],
          to: receiver,
          quantity: amountToPay,
          token: 'USDT',
          status: 'CONFIRM',
          rti_quantity: qtys[1],
          gas_used: receipt.gasUsed.toString(),
          bundle_name: selectedBundle.id,
          bundle_quantity: coinsToReceive
        };
        console.log("receiptData : ",receiptData)
        await fetch(config.API_BASE_URL+'/transaction/transactionReceipt', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(receiptData)
        });
      })
      .on('error', (error) => {
        console.error("Error sending USDT:", error);
        alert(`Error: ${error.message}`);
      });
    } catch (error) {
      console.error("Error:", error);
      alert(`Error: ${error.message}`);
    }
  };


  // const handleAmountChange = (e) => {
  //   const amount = e.target.value;
  //   setAmountToPay(amount);
    
  // };

  const quantities = (opt,amt) => {
    let ret = [amt,0];
    if(opt === 'rti'){
      ret[1] = ((amt * 0.3 ) / 100.0)
    }
    return ret 
    
    
  }

  const handleRedeem = async (token, balance, nav, current_value) => {
    try {
      const response = await fetch(config.API_BASE_URL+'/redeem', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token, quantity:balance, nav, current_value,wallet_address:primaryWallet })
      });
      
      const data = await response.json();
      if (response.ok) {
        alert('Redemption successful!');
        console.log(data); // Log or handle data from server
      } else {
        throw new Error(data.message || 'Failed to redeem');
      }
    } catch (error) {
      console.error('Redeem error:', error);
      alert(error.message);
    }
  };

  const handleAmountChange = (event) => {
    let newValue = event.target.value;
    // Check if the newValue is within the selectedBundle's min and max
    let min = Number(selectedBundle.minimum_quantity);
    let max = Number(selectedBundle.maximum_quantity);
    console.log(newValue,min,min)
    if (newValue >= min && newValue <= max) {
      setAmountToPay(newValue);
    } else if (newValue < Number(min)) {
      setAmountToPay(min);
      newValue = min ; 
      console.log("min : ",newValue);
    } else if (newValue > Number(max)) {
      setAmountToPay(max);
      newValue = max ; 
      console.log("max : ",newValue);
    }
    console.log(newValue, selectedBundle.nav);
    calculateCoins(newValue, selectedBundle.nav);
  };
  

  const handleBundleChange = (e) => {
    const bundleName = e.target.value;
    const bundle = bundles.find(bundle => bundle.name === bundleName);
    setSelectedBundle(bundle);
    calculateCoins(amountToPay ? amountToPay :  bundle.minimum_quantity  , bundle.nav);
  };

  const calculateRtiCoins = (amount) => {
    let rabbitCoins = amount / 100.0;
    console.log(amountToPay,100.0,rabbitCoins);
    
    setRtiCoinsToReceive(rabbitCoins);

  }

  const calculateCoins = (amount, nav) => {
    if (!amount || !nav) return;
    setCoinsToReceive(amount / nav);
    if(selectedOption === 'rti'){
      calculateRtiCoins(amount)

    }
  };

  let investDisable = false;
  let investMsg = "Invest"

  if(!window.ethereum){
    investMsg = "Install Metamask"
    investDisable = true ;
  }
  else if(selectedNetwork != 56){
    investMsg = "Wrong Network"
    investDisable = true;
  }
  else {
    investMsg = "Invest"
    investDisable = false;
  }


  const renderTabContent = () => {
    switch (selectedTab) {
      case 'Invest':
        return <><div className="input-group flex-column select-portfolio">
        <label> Select Porfolio</label>
        <select value={selectedBundle && selectedBundle.name || ""} onChange={handleBundleChange}>
          {bundles.map(bundle => (
            <option key={bundle.name} value={bundle.name}>{bundle.name}</option>
          ))}
        </select>
        <span>NAV : {selectedBundle && selectedBundle.nav || ""}</span>
        {/* <span>Minimum Investment : {selectedBundle && selectedBundle.minimum_quantity || ""}</span>
        <span>Maximim Investment : {selectedBundle && selectedBundle.maximum_quantity  || ""}</span> */}
        
      </div>
      <div className='input-group you-pay-input'>
        <div>
          
          <label>You pay</label>
          <input
            type="number"
            value={amountToPay ? amountToPay : Number(selectedBundle?.minimum_quantity || 20)}
            placeholder="0"
            min={Number(selectedBundle?.minimum_quantity || 20)} // Provide a default minimum if not specified
            max={Number(selectedBundle?.maximum_quantity || 30)} // Provide a default maximum if not specified
            onChange={handleAmountChange}
          />
          
        </div>
        <div className=''>
          <div className='align-content-center coin-label'>
            <img src="/usdt.webp" height={20} width={20} ></img>
            <p className='no-margin'> USDT</p>
          </div>
          <div>
            <p className='balance-p'>Balance : {usdtBalace}</p>
            <p className='total-cost'>Total Cost : {amountToPay} {selectedOption === 'rti' && `+  ${quantities(selectedOption,amountToPay)[1].toFixed(4) }`}</p>
          </div>

          
        </div> 
        
        
        
      </div>
      
           
      <div className="input-group you-recieve-input">
        <div>
          <label>You receive</label>
          <label className='purple'>{selectedBundle?.id || 0} coins</label>
          <input type="text" value={coinsToReceive.toFixed(2) || ''} readOnly />
        </div>
          { selectedOption === 'rti' && (
            <div>
              <label>  - </label>
              <label className='purple'>RabBit coins</label>
              <input type="text" value={rtiCoinsToReceive.toFixed(5) || ''} readOnly />
            </div>
          )

          }
          
        {/* <span>{selectedBundle.name}</span> */}
        
      </div>
      <div className="options">
        <div className="radio-option">
          <input
            type="radio"
            id="rti"
            name="managementFee"
            value="rti"
            checked={selectedOption === 'rti'}
            onChange={handleOptionChange}
          />
          <label htmlFor="rti">Buy with Rabbit coins</label>
          <span onClick={() => setShowInfoText(!showInfoText)}></span>
          {showInfoText && <p>Buy with 0 RTI and no management fee</p>}
        </div>

        <div className="radio-option">
          <input
            type="radio"
            id="fee"
            name="managementFee"
            value="fee"
            checked={selectedOption === 'fee'}
            onChange={handleOptionChange}
          />
          <label htmlFor="fee">Buy with 1% per annum management fee</label>
        </div>
    </div>


      <button className={`invest-button-app ${investDisable}`} disabled={investDisable} type='submit' onClick={handleSend} onSubmit={handleSend}>{investMsg}</button>
      </>
      case 'Redeem':
        return <div>
          <table>
          <th className='coin-row'>
            <td className='width-30'>Token</td>
            <td className='width-30'>Balance</td>
            <td className='width-30'>Nav</td>
            <td className='width-30'>Current_value</td>
            <td className='width-30'> - </td>
          </th>
          {walletData ? (walletData.map(({ token, balance, nav, current_value, change, title }, index) => (
          <tr className='coin-row'>
            <td className='width-30'>{token}</td>
            <td className='width-30'>{parseFloat(balance).toFixed(4)}</td>
            <td className='width-30'>{parseFloat(nav).toFixed(4)}</td>
            <td className='width-30'>{parseFloat(current_value).toFixed(4)}</td>
            <td className='width-30'>{token != "RabBit" && <button className='redeem-btn' onClick={() => handleRedeem(token, balance, nav, current_value)} >Redeem</button>}</td>
          </tr>
            
          
        ))) : <p> You don't have investments to show yet. </p> }
        
        </table>
      </div>;
      case 'Transfer':
        return <div><h2>Coming Soon</h2></div>;
      default:
        return <div>Invalid Tab</div>;
    }
  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };



  return (
    <>
          <Joyride
        continuous
        run={runTour}
        steps={steps}
        callback={handleJoyrideCallback}
        scrollToFirstStep
        showSkipButton
        styles={{
          options: {
            zIndex: 1000,
          },
        }}
      />
    <CommonHeader />
    <div className="InvestApp">
      
      <div className="swap-container">
        
        <div className="tabs">
          {['Invest', 'Redeem', 'Transfer'].map(tab => (
            <div key={tab} className={`tab ${selectedTab === tab ? 'active ' : ''} ${tab.toLowerCase()}`} onClick={() => handleTabClick(tab)}>
              {tab}
            </div>
          ))}
        </div>
        {renderTabContent()}

    </div>
    </div>
    </>
  );
}

export default InvestApp;
