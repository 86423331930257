import React from 'react';
import HeaderBar from '../components/HeaderBar';

const NotFound = () => {
  return(
    <>
        <HeaderBar />
        <div>
          <h2>The Page you are looking for doesn't exist.</h2>
          <h4>404 : Not Found</h4>
        </div>
    </>

)
};

export default NotFound;