import React, { useState, useEffect } from 'react';
import './BundleContainer.css';

import { fetchBundles } from '../Utils/BackendFetch';


function BundleContainer() {
    const [bundles, setBundles] = useState([]);  // State to store the fetched bundles

    useEffect(() => {
        // Function to fetch bundles data from the backend
        // const fetchBundles = async () => {
        //     try {
        //         const response = await fetch(config.API_BASE_URL+'/bundles');
        //         const data = await response.json();
        //         setBundles(data);  // Store the bundles in state
        //     } catch (error) {
        //         console.error('Failed to fetch bundles:', error);
        //     }
        // };
        const getBundles = async () =>{
          let data = await fetchBundles();  // Call the fetch function
          setBundles(data);

        }

        getBundles()
    }, []);  // The empty dependency array ensures this effect runs only once after the component mounts

  return (
    <div className="bundleContainer">
      <div className='bundle-header'>  
        <h1>WHAT ARE AND WHY PORTFOLIOS</h1>
        <p className="no-padding bundle-desc">Portfolios are diversified groups of algos organized by themes that could make sense to different types of users. They can be thought of as funds.
</p><p className='bundle-desc'>Individual algorithms might be too undiversified and are likely to suffer from instrument specific or time specific risks. Best way to avoid them is to have some form of diversification be it by instrument or by timeframe or by philosophy. 
</p>  
      </div>
      <div className='bundleWrapper'>

        {bundles.map((bundle, index) => (
          <a href={`/products/${bundle.id}`} className='bundle-anchor no-text-decoration'>
            <div className="bundle">
            <div className='bundle-name'>
              <span >{bundle.id}</span>
            </div>
            <div className='bundle-desc'>
            <div className='bundle-logo' >
              <h2 className='bundle-h1'>{bundle.name}</h2>
              {/* <img  src={'/bundle-logo.png'} alt={`${bundle.name} logo`} /> */}
              {/* <span class="SmallcaseTag__purple-tag__nyYqh SmallcaseTag__large-tag__LCsI2 tag-lg">{bundle.title}</span> */}
            </div>
            <p className="bundle-description less-margin">{bundle.description}</p>
            <p className='read-more'>Read more  <i class="fas fa-long-arrow-alt-right"></i></p>
            {/* <p className="bundle-nav less-margin">{bundle.nav}</p> */}
            <a href='/app'><button className="buy-button">Invest</button></a>
            </div>
          

          <div className="bundleTable">
              <div className="bundleTableRow">
                {/* <div className="bundleTableCell">NAV</div> */}
                <div className="bundleTableCell">Annual Return</div>
                {/* <div className="bundleTableCell">Max Downside</div> */}
              </div>
              <div className="bundleValueRow">
                {/* <div className="bundleTableCell">{bundle.nav}</div> */}
                <div className="bundleTableCell">{bundle.cagr}</div>
                {/* <div className="bundleTableCell">{bundle.mds}</div> */}
              </div>
            </div>
            </div>
          
          
          </a>
        ))}
      </div>
    </div>
  );
}

export default BundleContainer;
