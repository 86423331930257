import React from 'react';
import styled from '@emotion/styled';
import "./AboutUs.css";

const Container = styled.div`
  padding: 150px 10%;
`;



const Heading = styled.h1`
  color: var(--rabbitcha-gold);
  text-align: center;
`;

const Section = styled.section`
  margin-bottom: 30px;
`;

const Paragraph = styled.p`
  line-height: 1.6;
  text-align: justify;
`;

const Bold = styled.b`
  font-weight: bold;
`;

const UnorderedList = styled.ul`
  
`;

const ListItem = styled.li`
  margin-bottom: 10px;
`;

const Link = styled.a`
  color: #000;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const TokenArch = () => (
    <Container>
    <div>
      <span className='tokenomics'>TOKENOMICS</span>
        <img src="/tokenomics_table.svg" />
        <img src="/tokenomics_summary.svg" />
        <img src="/tokenomics_pie.svg" />        
    </div>
    <Heading>Token architecture</Heading>
    <Section>
        <Paragraph>
        As any asset management business, RabBitCha.money charges management fee and profit share. These are wired into the contracts of RabBitCha.money’s tokens. There will be 2 types of tokens. 
        </Paragraph>
        <UnorderedList>
            <ListItem>
              <u><b>RabBITCOIN:</b> </u>
This represents the management fee collected (Approx 1% of AUM per annum). The management fee collected accrues to this token if it is owned by a customer or distributor who brings in capital. Customers however are not compelled to own this in order to invest in RabBitCha.money’s algorithm portfolios. Just that owning this is an offer customer can’t refuse easily. Also, RabBITCOINs are redeemable at the accrued value. They come in limited supply though and the limit is TBD.

Note: In the demo, this has been designed to just waive off management fee.

            </ListItem>
            <ListItem>
            <u><b>Multiple BunnyMoney tokens:</b></u>
These are tokens that represent the actual algorithm portfolios. They are also designed to pass on the management fee to the developers of algorithms that make up portfolios, if applicable. These are redeemable at NAV minus costs of redemption (like gas fee). The supply of these will be a function of the capacity of the underlying algorithms to take in capital. 

These redemption and accrual mechanisms are designed to protect the tokens (if listed) from supply shocks and bear market pressures. They have the similar effect if BTC mining cost.

            </ListItem>
        </UnorderedList>

    </Section>
    <Section>
        
    </Section>
    </Container>
);


export default TokenArch;