import React from 'react';
import FAQComponent from '../components/FAQComponent';
import Footer from '../components/Footer';
import TokenArch from '../components/TokenArchitecture';
import HeaderBar from '../components/HeaderBar';


function TokenArchitecture(){

    return(
        <>
            <HeaderBar />
            <TokenArch />
            <FAQComponent />
            <Footer />
        </>

    )

}

export default  TokenArchitecture;