import config from '../config';
export async function fetchBundles(){
    try {
        const response = await fetch(config.API_BASE_URL+'/bundles');
        const data = await response.json();
        return data;  // Store the bundles in state
    } catch (error) {
        console.error('Failed to fetch bundles:', error);
        return []
    }
};