import React, { useRef , useState , useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import InvestApp from './components/InvestApp';
import Home from './Pages/Home';
import Products from './Pages/Products';
import About from './Pages/About';
import { ModalProvider } from './Contexts/ModalContext';
import Modal from './components/Modal';
import { MetamaskProvider } from './Contexts/MetamaskContext';
import Cookies from 'js-cookie';
import Login from './components/Login';
import TokenArchitecture from './Pages/TokenAcrchitecture';
import NotFound from './Pages/NotFound';
// import SlideShow from './components/Login';





function App() {
  const [isWalletConnected, setIsWalletConnected] = React.useState(false);
  const [primaryWallet, setPrimaryWallet] = React.useState(false);
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const [isAuthenticated, setIsAuthenticated] = useState(false);



  useEffect(() => {
    const isLoggedIn = Cookies.get('_wRthwytSgt') === '_fdsarTyfer';
    setIsAuthenticated(isLoggedIn);
    const handleLocationChange = () => {
      setCurrentPath(window.location.pathname);
    };

    // Listen for popstate event in case of back/forward navigation
    window.addEventListener('popstate', handleLocationChange);

    return () => {
      window.removeEventListener('popstate', handleLocationChange);
    };
  }, []);

  const handleLoginSuccess = () => {
    setIsAuthenticated(true);
    Cookies.set('_wRthwytSgt', '_fdsarTyfer', { expires: 10 / 24 }); // Expires in 10 hours
  };

  // Simple routing based on the currentPath state
  

  // Refs for scrolling
  const aboutUsRef = useRef(null);
  const bundleContainerRef = useRef(null);

  // Scroll to ref function
  const scrollToRef = (ref) => window.scrollTo({ top: ref.current.offsetTop, behavior: 'smooth' });

  return (
    <div className="App">
      <ModalProvider>
          <Router>
            <Routes>
              <Route path="/"  element={<Home
                  isWalletConnected={isWalletConnected}
                  setIsWalletConnected={setIsWalletConnected}
                  scrollToRef={scrollToRef}
                  aboutUsRef={aboutUsRef}
                  bundleContainerRef={bundleContainerRef}
                  setPrimaryWallet={setPrimaryWallet}
                  /> } />
                <Route path="/home" element={<Home
                  isWalletConnected={isWalletConnected}
                  setIsWalletConnected={setIsWalletConnected}
                  scrollToRef={scrollToRef}
                  aboutUsRef={aboutUsRef}
                  bundleContainerRef={bundleContainerRef}
                  setPrimaryWallet={setPrimaryWallet}
                  />} />
              <Route path="/about" element={<About /> }/>
              <Route path="/products/:productid" element={<Products />} /> 
              <Route path="/token" element={<TokenArchitecture />} />
              {/* <Route path="/slideshow" element={<SlideShow onLoginSuccess={handleLoginSuccess} />} /> */}
              <Route 
                path="/app" 
                element={isAuthenticated ? 
                  <MetamaskProvider>
                    <InvestApp />
                  </MetamaskProvider>
                : <Login onLoginSuccess={handleLoginSuccess} />} 
              />
                <Route path="*" element={<NotFound />}  />

              
            </Routes>
          </Router>
          <Modal />
      </ModalProvider>

      
    </div>
  );
}

export default App;
