import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import "./SlideShow.css";
import axios from 'axios';

const Slideshow = ({ onLoginSuccess }) => {
    const slides = [
        ['/slide1.jpg', "Start by browsing through the algo Portfolios and their performance stats we offer."],
        ['/slide2.jpg', "Explore all of them. Click on one of them to see detailed information about a portfolio"],
        ['/slide3.jpg', "Check the status and its performance. If you like it click on invest."],
        ['/slide4.jpg', "Connect your metamask wallet, transfer USDT over binance smartchain."],
        ['/slide5.jpg', "You can enter amount of USDTs you wish to invest to see number of redeemable Portfolios coins you will get in return."],
        ['/slide6.jpg', "Confirm the coin transfer on wallet to proceed."],
        ['/slide7.jpg', "Once deployment is successful. You will see Portfolio coins in your redeem dashboard."],
        ['/slide8.jpg', "Want to know more about us? Click on About us"],
        ['/slide9.jpg', "Find our Token architecture by clicking on 'Token Architecture'."],
    ];

    const [currentSlide, setCurrentSlide] = useState(0);
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    // Function to go to the next slide
    const nextSlide = () => {
        setCurrentSlide((prev) => (prev + 1) % slides.length);
    };

    // Function to go to the previous slide
    const prevSlide = () => {
        setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
    };

    // Swipe handlers
    const handlers = useSwipeable({
        onSwipedLeft: () => nextSlide(),
        onSwipedRight: () => prevSlide(),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    // Automatic slide transition
    useEffect(() => {
        const interval = setInterval(nextSlide, 5000); // Changes slide every 3 seconds
        return () => clearInterval(interval); // Clean up the interval on component unmount
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post('https://www.rabbitcha.money/api/appLogin', { password });
            if (response.data) {
                console.log("login success ", response.data)
                onLoginSuccess();
            } else {
                setError('Invalid password');
            }
        } catch (error) {
            console.error(error)
            setError('Failed to authenticate: ' + error.message);
        }
    };

    return (
        <div className='login-div'>
            <div id="hwi" className='slide-wrapper' {...handlers}>
                <div className='slide-left-nav slide-nav'>
                    <i className='fa fa-chevron-circle-left slide-btn purple' onClick={prevSlide}></i>
                </div>
                <div className='slide-img-div '>
                    <h4 className="hiw">How it works</h4>
                    <h5 className='hwi-label'>{slides[currentSlide][1]}</h5>
                    <img className="slide-img" src={slides[currentSlide][0]} alt={`Slide ${currentSlide}`} />
                </div>
                <div className='slide-right-nav slide-nav'>
                    <i className='fa fa-chevron-circle-right slide-btn purple' onClick={nextSlide}></i>
                </div>
            </div>
            <div className='app-login-div'>
                <form onSubmit={handleSubmit}>
                    <input className='app-login-pw' type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Enter passcode" />
                    <button className='app-login-btn' type="submit">Enter</button>
                    <a href="/#hwi" className='no-text-decoration'><div className='app-login-btn hwi-btn'>How it works</div></a>
                    {error && <p>{error}</p>}
                </form>
            </div>
        </div>
    );
};

export default Slideshow;
