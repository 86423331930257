import { useEffect, useState, React} from 'react';
import './HeaderBar.css';


function CommonHeader(){
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
  
    const toggleMenu = () => {
      setIsMenuVisible(!isMenuVisible);
    };
  
    useEffect(() => {
      const onScroll = () => {
        if (window.scrollY > 0) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    }
    });

    return (
        <div className={`HeaderBar ${isScrolled ? 'scrolled' : ''}`}>
        <a href='/'><div className="logo"><img src="/logo.svg" height="50" /></div></a>
        <div className={`navigation ${isMenuVisible ? 'visible' : ''}`}>
            <a href="/about" className='no-text-decoration about'><span>About Us</span></a>
            <a href="/products" className='no-text-decoration porfolios'><span>Portfolios</span></a>
            <a href="/token" className='no-text-decoration'><span>Token architecture</span></a>
            {/* {!isWalletConnected && <button onClick={connectWallet} className="header-button">Connect Wallet</button>} */}
            <a href="/app" target='_blank' className='no-text-decoration'><button className="header-button">INVEST</button></a>
        </div>
        <div className="menu-icon" onClick={toggleMenu}>☰</div>
        </div>
    );
}

export default  CommonHeader;