import {Web3} from "web3";
const BigNumber = require('bignumber.js');


const tokenABI = {
    "balanceOf" : [
        // The minimum ABI required to retrieve an ERC-20 token balance
        {
            "constant": true,
            "inputs": [{"name": "_owner", "type": "address"}],
            "name": "balanceOf",
            "outputs": [{"name": "balance", "type": "uint256"}],
            "type": "function"
        }
    ]
}

export async function getUsdtBalance(account){
    console.log("account : ",account)
    const tokenAddress = "0x55d398326f99059fF775485246999027B3197955";
    const tokenAbi = tokenABI["balanceOf"];
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(tokenAbi, tokenAddress);
    const balance = await contract.methods.balanceOf(account).call();
    const balanceBN = new BigNumber(balance.toString());  // Create a BigNumber from the BigInt
    const tokenDecimals = 18; // This should be adjusted based on the token's decimals
    const adjustedBalance = balanceBN.dividedBy(new BigNumber(10).pow(tokenDecimals)).toString();
    console.log(balance)
    console.log(`Token Balance: ${adjustedBalance.toString()}`);
    return adjustedBalance;


}

export async function getCurrentAccount(){
    try{
        const web3 = new Web3(window.ethereum);
        const accounts = await web3.eth.getAccounts();

        if (accounts.length === 0) {
            console.log("No accounts found. Ensure MetaMask is unlocked and connected.");
            return null;
        }

        console.log("Current selected account:", accounts[0]);
        return accounts[0];

    }catch(error){
        console.log("Unable to get account details")
        
    }
    return ""
    
}

export async function switchToBNB(){

    try {
        let chainId = 56
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${chainId.toString(16)}` }], // Hexadecimal chainId
        });
      } catch (switchError) {
        console.log(switchError);
        alert("We are unable to switch to BNB smart chain. Please switch it manually.")

      }


}