// FAQComponent.jsx
import React, { useState } from 'react';
import './FAQComponent.css';

const FAQItem = ({ faq, index }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const toggleFAQ = () => {
      setIsOpen(!isOpen);
    };
  
    return (
      <div key={index} className={`faq-item ${isOpen && "faq-item-open" || ""}`} onClick={toggleFAQ}>
        <h3>{faq.question}</h3>
        {isOpen && <p>{faq.answer}</p>}
      </div>
    );
  };

const FAQComponent = () => {
  const [activeTab, setActiveTab] = useState('GENERAL');

  const faqContent = {
    GENERAL: [
      { question: 'How safe is RabBitCha.money against exchange failures?', answer: 'We Understand that exchange failure possibilities are a reality. We intent to minimize the margin deposited with exchanges and to achieve that use a higher leverage on the margin money (thereby not changing the effective leverage). We also intent to diversify among multiple exchanges to minimize the potential impairment further.' },
      { question: 'How safe is RabBitCha.money against bear markets?', answer: 'We actually sort of like bear markets. The increased volatility is good for algorithmic trading. Most of our products will not be adversely affected  by bearish sentiment in the market. Long only products and long biased products might see a downside.' },
      { question: 'How safe is my money with RabBitCha.money?', answer: 'Your investment in any RabBitCha.money token is contractually redeemable at NAV minus any expenses (like gas fee) related to redemption. You will also be able to set auto redemption triggers on the NAV. They would work like a stop loss. ' },
      { question: 'Can I always earn market beating returns with RabBitCha.money?', answer: 'Not necessarily. In crazy bullish seasons, HODL may outperform most of our offerings.' },
      { question: 'Will my money be vulnerable to excessive leverage?', answer: 'No. You will be allowed to set the max leverage (effectively) that you are comfortable with. By the way we are not comfortable beyond 5x. We may use higher leverage than this on the margin deposited in the exchanges. However, the effective leverage will still be less than or equal to the lower among your tolerance level and ours.' },
      { question: 'What does RabBitCha.money do with capital unused for trading?', answer: 'Low risk and diversified yield farming.' },
      { question: 'Can the redeemability of my holdings be taken back by RabBitCha.money?', answer: 'No' },
      { question: 'How will the prevailing high interest rate environment affect RabBitCha.money’s produtcs?', answer: 'Not a lot, unless the environment affects the nature of this asset class like volatility.' },
      { question: 'Can RabBitCha.money’s tokens be bought on exchange?', answer: 'Not yet. But even after that, they will remain redeemable.' },
      { question: 'Does it make sense to buy more than 1 product from RabBitCha.money?', answer: 'Of course. Recommendation engines to max and match products to suit your goals and risk appetite coming soon too.' },
      { question: 'How is RabBitCha.money different from grayscale or hedge funds?', answer: 'Some of the Hedge funds in this asset class have questionable custodial practices. Grayscale type of ETF products are not very smart at doing anything other than tracking the underlying. We are more transparent than hedge funds and more intelligent than passive investment houses.' },
      { question: 'How does the tokenomics of RabBitCha.money’s products work?', answer: 'Coming Soon.' },
      // ... more general questions
    ],
    INVEST: [
        { question: 'What is Ico Crypto?', answer: 'Lorem Ipsum...' },
        { question: 'How can I participate in the ICO Token sale?', answer: 'Lorem Ipsum...' },
        // ... more general questions
      ],
    BUNDLES: [
        { question: 'What is Ico Crypto?', answer: 'Lorem Ipsum...' },
        { question: 'How can I participate in the ICO Token sale?', answer: 'Lorem Ipsum...' },
        // ... more general questions
      ]
    // ... other categories
  };

  return (
    <div className="faq-container">
      <div className="faq-header">
        <h3 className='purple'>FAQ</h3>
        <h1 className='weight-400'>Frequently Asked Questions</h1>
        <p></p>
      </div>
      {/* <nav className="faq-nav">
        {Object.keys(faqContent).map((category) => (
          <button
            key={category}
            className={`nav-item ${activeTab === category ? 'active purple' : ''}`}
            onClick={() => setActiveTab(category)}
          >
            {category}
          </button>
        ))}
      </nav> */}
      <div className="faq-content">
        {faqContent[activeTab].map((faq, index) => (
          
            <FAQItem faq={faq} />
          
        ))}
      </div>
    </div>
  );
};

export default FAQComponent;
