import React, { useRef , useState , useEffect} from 'react';
import HeaderBar from './../components/HeaderBar';
import InfoDiv1 from './../components/InfoDiv1';
import HowItWorks from './../components/HowItWorks';
import BundleContainer from './../components/BundleContainer';
import Footer from '../components/Footer';
import FAQComponent from '../components/FAQComponent';





function Home({isWalletConnected,setIsWalletConnected,scrollToRef,aboutUsRef,bundleContainerRef,setPrimaryWallet}){

    return (
        <div className="App">
            <HeaderBar
            isWalletConnected={isWalletConnected}
            setIsWalletConnected={setIsWalletConnected}
            scrollToRef={scrollToRef}
            aboutUsRef={aboutUsRef}
            bundleContainerRef={bundleContainerRef}
            setPrimaryWallet={setPrimaryWallet}
            />
            <InfoDiv1 
            scrollToRef={scrollToRef}
            bundleContainerRef={bundleContainerRef}/>
            <HowItWorks />
            <div ref={bundleContainerRef}><BundleContainer /></div>
            <FAQComponent />
            
            <Footer />

      </div>


    );
}

export default Home;