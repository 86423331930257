// Modal.js
import { useModal } from './../Contexts/ModalContext';
import "./Modal.css";

const Modal = () => {
  const { isModalOpen, modalContent, modalTitle, onConfirm, showModal, hideModal  } = useModal();
  if (!isModalOpen) return null;
  console.log("isModalOpen : ",isModalOpen)
  

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <h4 className="modal-title">{modalTitle}</h4>
          <button className="modal-close" onClick={hideModal}>&times;</button>
        </div>
        <div className="modal-body">{modalContent}</div>
        <div className="modal-footer">
          <button onClick={onConfirm}>Yes</button>
          <button onClick={hideModal}>No</button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
