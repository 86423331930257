import React, { useState, useEffect, useRef } from 'react';
import "./SpecificProduct.css";
import Chart, { Colors } from 'chart.js/auto';
import config from '../config';

function SpecificProduct({ productid }) {
    const [product, setProduct] = useState({});
    const [dataLoaded, setDataLoaded] = useState(false); // Flag to check data load
    const chartRef = useRef(null); // Holds the chart instance
    const chartContainerRef = useRef(null); // Ref to the canvas container

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch product data
                const productResponse = await fetch(`${config.API_BASE_URL}/bundles?id=${productid}`);
                const productData = await productResponse.json();
                setProduct(productData[0]);

                // Fetch historical data
                const historyResponse = await fetch(`${config.API_BASE_URL}/bundleData?id=${productid}`);
                const historyData = await historyResponse.json();

                if (historyData.length > 0) {
                    initializeChart(historyData);
                } else {
                    console.log("No historical data available");
                }
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        };

        fetchData();
    }, [productid]);

    const initializeChart = (dataYAxis) => {
        const dataXAxis = dataYAxis.map((_, index) => `${index + 1}`);
        console.log(!chartContainerRef.current , !chartRef.current)

        if (!chartContainerRef.current && !chartRef.current) {
            console.log("Initialized")
            const ctx = document.getElementById('myChart').getContext('2d');
            chartRef.current = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: dataXAxis,
                    datasets: [{
                        label: 'Cumulative MTM',
                        data: dataYAxis,
                        fill: true,
                        borderColor: '#ffd700',
                        tension: 0.1
                    }]
                },
                transitions : {
                  active:{
                    animation:{duration:0}

                  }

                },
                options: {
                  animation: false,
                  hover: {
                    animationDuration: 0 // duration of animations when hovering an item
                  },
                  responsiveAnimationDuration: 0, // animation duration after a resize
                  scales: {
                        y: {
                            grid: { display: false },
                            beginAtZero: true
                        },
                        x: {
                            grid: { display: false },
                            beginAtZero: true
                        }
                    },
                    interaction: {
                        mode: 'index',
                        intersect: false,
                    },                    
                    plugins: {
                      zoom:{
                        zoom:{
                          drag:{
                             enabled:true,
                          },
                          mode:'x',
                        }
                      },
                      tooltip: {
                            mode: 'index',
                            intersect: false,
                        },
                    }
                }
            })
        }
    }

    // Cleanup on unmount
    useEffect(() => {
        return () => {
            if (chartRef.current) {
                chartRef.current.destroy();
            }
        }
    }, []);

    return(
        <>
        <div className='product-description'>
            <img src={`/${product.id}.png` } height="200" width="200"></img>
            <h5 className='weight-500'>{product.id}</h5>
            <h1 className='weight-500'>{product.name}</h1>
            <h3 className='weight-500'>{product.desc_long}</h3>
            <a href="/app"><button className='prod-invest-btn'>INVEST</button></a>
        </div>
        <div className='charts-stat-wrapper'>
            <div>
                <h2>{product.name}</h2>
                <canvas id="myChart"></canvas>


            </div>
            <div class="status-card">
            <div class="card-row header">
                    <div class="card-label">NAV</div>
                    <div class="card-value">{product.nav}</div>
                </div>
                <div class="card-row">
                    <div class="card-label">Expected Annual returns</div>
                    <div class="card-value">{product.cagr}</div>
                </div>
                <div class="card-row">
                    <div class="card-label">Maximim draw down</div>
                    <div class="card-value">{product.mds}</div>
                </div>
                <div class="card-row">
                    <div class="card-label">Expected sharpe</div>
                    <div class="card-value">{product.sharp}</div>
                </div>

            </div>


        </div>
        </>
    )

}

export default SpecificProduct;