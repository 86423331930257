import React from 'react';
import { useParams } from 'react-router-dom';
import BundleContainer from '../components/BundleContainer';
import FAQComponent from '../components/FAQComponent';
import Footer from '../components/Footer';
import SpecificProduct from '../components/SpecificProduct';
import HeaderBar from '../components/HeaderBar';




function Products(){
    const { productid } = useParams();
    console.log("productid : ",productid)

    return(
        <>
            <HeaderBar />
            { productid && <SpecificProduct productid={productid} /> }
            { !productid && <BundleContainer /> }
            <FAQComponent />
            <Footer />
        </>

    )

}

export default  Products;