import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

const FooterContainer = styled.footer`
  display: flex;
  justify-content: space-evenly;
  padding: 40px;
  background-color: #061523;
  color: white;
  font-family: 'roboto';
  font-size: 18px;
  
`;

const FooterSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const a = styled(Link)`
  color: #555763;
  text-decoration: none;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 400;
  margin: 10px 0;

  &:hover {
    text-decoration: underline;
  }
`;

const Disclaimer = styled.div`
  margin-top: 40px;
  padding-top: 20px;
  color: rgb(134 141 148);
`;

const CopyRight = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const Footer = () => {
  return (
    <>
    <FooterContainer>
      <div className='footer-wrap'>

    <FooterSection>
      <a href="/">
        <div className="logo"><img src="/logo.svg" height="50" /></div>
      </a>
      <br/>
      <br/>
      <br/>
      <p>Follow us on </p>
      <br/>
      <div className='icon-wrapper'>
      <a className="no-text-decoration" href='https://www.youtube.com/channel/UCHfqE6hg-5fL_eeFsFRAkSA' target='_blank'><i className='social-mdeia-icon fab fa-youtube'></i></a>
      <i className='social-mdeia-icon  fab fa-linkedin'></i>
      <i className='social-mdeia-icon  fab fa-twitter'></i>
      </div>


    </FooterSection>
      <FooterSection>
        <h4>Products</h4>
        {/* List your products or services */}
        <a className="no-text-decoration no-padding" href="/products/BDLS" target="_blank">Diversified Long Short</a>
        <a className="no-text-decoration no-padding" href="/products/BDMS" target="_blank">Diversified Market neutral</a>
        <a className="no-text-decoration no-padding" href="/products/BDAR" target="_blank">Diversified abs return</a>
        <a className="no-text-decoration no-padding" href="/products/BSOD" target="_blank">Short only</a>
        <a className="no-text-decoration no-padding" href="/products/BLOD" target="_blank">Long Only</a>
        <a className="no-text-decoration no-padding" href="/products/BPLS" target="_blank">Perpetual Long with Perpetual Short</a>
        {/* ... other product links */}
      </FooterSection>

      <FooterSection>
        <h4>Resources</h4>
        {/* List your resources */}
        <a className="no-text-decoration no-padding" href="/about" target="_blank">About Us</a>
        {/* ... other resource links */}
      </FooterSection>
      </div>
      </FooterContainer>

      {/* ... other sections like Connect With Us and Legal */}
      <FooterContainer>
      <Disclaimer>
      This is only a demo site and an early adopter version of the final product. The usage of the product is by invitation alone. RabBitCha.money team has limited the capital that can be experimented with on RabBitCha.money and the team is not liable for any presumed or notional or actual losses. Users who accept this choose to do so voluntarily.
The performance statistics mentioned are only indicative and can in no way construed to be promises. Past performance is not a guarantee of the future. 
RabBitCha.money team is liable for the malfunction of the website due to acts of god, war or any other emergencies. 
RabBitCha.money team has NOT yet conducted a security audit or VAPT and hence does not claim to be hackproof. Only users who understand this risk may use the website.
Any disclosure of personal information is voluntary and RabBitCha.money does NOT solicit any data that are deemed confidential – passwords, private keys etc. 
RabBitCha.money does not share your personal data with third parties. At the same time, RabBitCha.money is not responsible for unauthorized access / use of the same by third parties. 
The portfolios listed in the demo site are for illustration purposes and may not reflect the full-fledged portfolios.
RabBitCha.money has limited the maximum investment in a portfolio to $20 in the demo website. RabBitCha.money is not liable to compensate any losses to any individual or entity beyond this.  
The eventual token architecture not reflected in the demo. 


      </Disclaimer>

      <CopyRight>© Copyright rabitcha.money 2024</CopyRight>
    </FooterContainer>
    </>
  );
};

export default Footer;
