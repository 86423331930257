// src/components/InfoDiv1/index.js
import { useEffect, useState, React} from 'react';
import './InfoDiv1.css'; // Make sure to create an accompanying CSS file

const InfoDiv1 = ({ scrollToRef , bundleContainerRef }) => {
  return (
    <div className="info-div1">
      <div className='info-txt-img'> 
        <h1>Crypto is <span className='purple'>volatile.</span><br />Your wealth doesn’t have to be. </h1>
        <p>Multiplying wealth predictably is a fundamental right.</p>
        <button className='portfolios-button' onClick={() => scrollToRef(bundleContainerRef)}>PORTFOLIOS</button>
      </div>
      {/* <div className='banner-img-div'>
        <img src="/golden-rabbit.png" alt="Cryptocurrency coins" className="banner-image" />\
      </div> */}
    </div>
  );
};

export default InfoDiv1;
