// MetamaskProvider.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { getCurrentAccount, getUsdtBalance } from '../Utils/MetaMaskUtils';


// Create a context for the Metamask state
const MetamaskContext = createContext();

// Use this hook to access the context from components
export const useMetamask = () => useContext(MetamaskContext);

async function setAccountAndBalance(setSelectAccount,setUsdtBalace){
    const account = await getCurrentAccount();
    const usdtBalance = await getUsdtBalance(account);
    setSelectAccount(account);
    setUsdtBalace(usdtBalance);

}


async function setNetwork(setSelectedNetwork,setSelectAccount,setUsdtBalace){
  console.log("setNetwork")
  if (window.ethereum) {
    try {
      const networkId = await window.ethereum.request({ method: 'net_version' });
      setSelectedNetwork(networkId);
      setAccountAndBalance(setSelectAccount,setUsdtBalace);
    } catch (error) {
      console.error("Error getting network ID:", error);
    }
  }
  
}

export const MetamaskProvider = ({ children }) => {
  // Metamask state and functions
  const [walletAddress, setWalletAddress] = useState(null);
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [selectAccount, setSelectAccount] = useState(null);
  const [usdtBalace, setUsdtBalace] = useState(null);
  
  useEffect(()=>{
    async function loadNetwork(){
        if(!selectedNetwork){
            await setNetwork(setSelectedNetwork,setSelectAccount,setUsdtBalace)
        
        
          }

    }
    loadNetwork()
    

  },[]);
  
  
  
  if (window.ethereum) {
    window.ethereum.on('chainChanged', (chainId) => {
      console.log("Network changed to:", chainId);
      setSelectedNetwork(chainId);
      
    });
  } 

  // ... other state variables and functions


  // Value to pass to the context
  const value = {
    walletAddress,
    setWalletAddress,
    selectedNetwork,
    selectAccount,
    usdtBalace
    // ... other state variables and functions
  };

  return (
    <MetamaskContext.Provider value={value}>
      {children}
    </MetamaskContext.Provider>
  );
};
